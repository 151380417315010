<template>
    <div class="container-fluid core-view d-flex flex-column">
        <RouterView />

        <BRPolicySystemSelectModal />
    </div>
</template>

<script>
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';

    import BRPolicySystemSelectModal from '@/components/BRPolicySystemSelectModal.vue';

    // @group Views
    export default {
        name: 'BRRules',
        components: {
            BRPolicySystemSelectModal,
        },
        computed: {
            ...mapState(['currentPolicySystem']),
            ...mapGetters('toolbox', ['formattedPolicySystems']),
        },
        async created() {
            await this.init();
        },
        methods: {
            async init() {
                await this.setPolicySystem();
            },
            async setPolicySystem() {
                try {
                    await this.fetchPolicySystems();

                    const policySystemId = this.$route.params.policySystemId || this.currentPolicySystem?.id,
                          policySystem = this.formattedPolicySystems.find(p => p.id == policySystemId); // eslint-disable-line eqeqeq

                    if (policySystem) {
                        this.SET_CURRENT_POLICY_SYSTEM(policySystem);

                        if (!this.$route.params.policySystemId) {
                            this.$router.push({ name: 'admin.rules.list', params: {policySystemId: policySystem.id}});
                        }
                    } else {
                        this.$bvModal.show('policySystemSelect');
                    }
                } catch (error) /* istanbul ignore next */ {
                    utils.console.log(error);
                }
            },
            ...mapActions('toolbox', [
                'fetchPolicySystems'
            ]),
            ...mapMutations([
                'SET_CURRENT_POLICY_SYSTEM'
            ]),
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
</style>
