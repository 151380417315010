<template>
    <BModal
        id="policySystemSelect"
        ref="policySystemSelect"
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        static
        modal-class="bar bar-primary"
        title="Select Policy System"
        @show="onModalShow"
        @close="$ga.event('Policy System Select Modal', 'Close Policy System Modal', 'action')"
    >
        <div class="col-12">
            <div
                v-if="!hasPolicySystemSelection"
                class="alert alert-info justify-content-center"
            >
                You'll need to select a policy system to view its rules.
            </div>

            <ValidationObserver
                ref="validationObserver"
                class="w-100"
                tag="div"
            >
                <ValidationProvider
                    v-slot="{errors}"
                    class="form-group"
                    tag="div"
                    name="title"
                    rules="required"
                >
                    <label
                        id="policySystemLabel"
                        class="form-control-label"
                    >
                        Policy System
                    </label>

                    <Multiselect
                        v-model="policySystem"
                        :allow-empty="false"
                        data-cy="policySystemSelect"
                        data-vv-as="Policy System"
                        deselect-label=""
                        label="label"
                        name="policySystem"
                        :options="policySystemOptions"
                        placeholder="begin typing a policy system..."
                        select-label=""
                        track-by="value"
                    />

                    <ValidationError
                        :errors="errors"
                        target="policySystemLabel"
                        tool-tip-container="policySystemSelect"
                        triggers=""
                    />
                </ValidationProvider>
            </ValidationObserver>
        </div>

        <template #modal-footer>
            <button
                type="button"
                class="btn btn-primary"
                @click="selectPolicySystem"
            >
                View Rules
            </button>

            <button
                v-if="hasPolicySystemSelection"
                class="btn btn-secondary"
                data-cy="cancelButton"
                @click="hide"
            >
                Cancel
            </button>
        </template>
    </BModal>
</template>

<script>
    import ValidationError from '@imt/vue-toolbox/src/components/ValidationError.vue';
    import Multiselect from 'vue-multiselect';
    import {
        mapGetters,
        mapState
    } from 'vuex';

    import {dropdownUtils} from '@/utilities';

    // @group Modals
    export default {
        name: 'BRPolicySystemSelectModal',
        components: {
            Multiselect,
            ValidationError,
        },
        data() {
            return {
                policySystem: null,
            };
        },
        computed: {
            hasPolicySystemSelection() {
                return !!this.$route.params.policySystemId;
            },
            policySystemOptions() {
                return this.formattedPolicySystems.map(system => {
                    return {
                        label: system.title,
                        value: system.id.toString(),
                    };
                });
            },
            ...mapState([
                'currentPolicySystem',
            ]),
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
        },
        methods: {
            hide() {
                this.$bvModal.hide('policySystemSelect');
                // When hide is called and the modal has been told to hide.
                this.$emit('hidden');
            },
            async selectPolicySystem() {
                if (!await this.$refs.validationObserver.validate()) {
                    return;
                }

                // Selects Policy System
                // @arg SelectedPolicySystem
                const previousPolicySystemId = this.currentPolicySystem?.id;
                this.$emit('select-policy-system', this.policySystem.value);
                this.$store.commit('SET_CURRENT_POLICY_SYSTEM', this.formattedPolicySystems.find(policySystem => {
                    return policySystem.id == this.policySystem.value; // eslint-disable-line eqeqeq
                }));

                this.$ga.event('Policy System Select Modal', 'Select Policy System', 'action', this.policySystem.value);
                this.hide();

                if (previousPolicySystemId !== this.currentPolicySystem.id || this.$route.name !== 'admin.rules.list') {
                    this.$router.push({ name: 'admin.rules.list', params: {policySystemId: this.currentPolicySystem.id}});
                }
            },
            onModalShow() {
                if (this.currentPolicySystem) {
                    this.policySystem = dropdownUtils.findOptions(this.currentPolicySystem.id.toString(), this.policySystemOptions)[0];
                }
            },
        },
    };
</script>
